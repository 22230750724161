import React, { useState, useEffect } from 'react';
import { useData } from '../../components/scripts/DataProvider';
import { VictoryBar, VictoryContainer ,VictoryChart, VictoryAxis, VictoryTheme, VictoryLabel, VictoryStack, VictoryLegend, VictoryGroup} from 'victory';
import { useLoading } from '../../components/handlers/LoadingHandler';

const colors = [
  "#4f46e5", // Indigo
  "#06b6d4", // Cyan
  "#10b981", // Emerald
  "#f59e0b", // Amber
  "#ef4444", // Red
  "#8b5cf6", // Purple
  "#ec4899", // Pink
  "#14b8a6", // Teal
];

export default function AnalyticsPage() {
  const { data, error } = useData();
  const { isLoading, setIsLoading } = useLoading();
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if (data) {
      setIsLoading(false);
    }
  }, [data, setIsLoading]);

  if (error) return <div>Error: {error}</div>;
  if (!data?.items) return <div>No data available</div>;
  
  const groupedData = data.items.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = {};
    }
    if (!acc[item.category][item.location]) {
      acc[item.category][item.location] = 0;
    }
    acc[item.category][item.location] += Number(item.quantity) || 0;
    return acc;
  }, {});


  const chartData = Object.entries(groupedData).map(([category, locations]) => ({
    x: category,
    ...Object.fromEntries(
      Object.entries(locations).map(([loc, qty]) => [loc, Number(qty) || 0])
    )
  }));


  const getCategoryDetails = (category) => {
    return data.items
      .filter(item => item.category === category)
      .map(item => ({
        x: item.name,
        y: Number(item.quantity) || 0
      }));
  };

  const handleBarClick = (event, data) => {
    setSelectedCategory(data.datum.x);
  };

  if (!chartData || chartData.length === 0) {
    return <div>No data to display</div>;
  }

  const availableLocations = chartData[0] ? Object.keys(chartData[0]).filter(key => key !== 'x') : [];
  setIsLoading(false)

  return (
    
    <div>
      <h2 className="text-2xl font-bold mb-4">Analytics</h2>
        <div className="h-48 w-full object-cover md:h-full md:w-1/2">
          <VictoryChart
            theme={VictoryTheme.material}
            // domainPadding={{ x: [50, 0] }}
            height={250}
            width={450}
            standalone={true}
            containerComponent={
              <VictoryContainer
                responsive={true}
                width={500}
              />
            }
          >
          <VictoryLegend
            x={420}
            y={100}
            orientation="vertical"
            gutter={20}
            style={{ 
              labels: { fontSize: 8 }
            }}
            data={availableLocations.map((location, index) => ({
              name: location,
              symbol: { fill: colors[index % colors.length] }
            }))}
          />
          <VictoryAxis 
            style={{
              tickLabels: { 
                angle: -45,
                textAnchor: 'end',
                fontSize: 6,
                padding: 4,
                
              }
            }}
            tickComponent={
              <VictoryLabel dx={-10} style={{ fontSize: 10, fill: "#000000" }}/>
            }
            tickLabelComponent={
              <VictoryLabel dx={-10} style={{ fontSize: 10, fill: "#000000" }}/>
            }
          />
            <VictoryAxis dependentAxis />
            <VictoryGroup offset={5}
           >
            {/* First stacked bar showing current data */}
              <VictoryStack>
                {availableLocations.map((location, index) => (
                  <VictoryBar
                    // barWidth={10}
                    alignment="end"
                    key={location}
                    data={chartData}
                    y={(datum) => {
                      const value = Number(datum[location]);
                      return isNaN(value) ? 0 : value;
                    }}
                    style={{ data: { fill: colors[index % colors.length] } }}
                    barRatio={0.8}
                    animate={{
                      duration: 500,
                      onLoad: { duration: 300 }
                    }}
                    events={[{
                      target: "data",
                      eventHandlers: {
                        onClick: handleBarClick
                      }
                    }]}
                  />
                ))}
              </VictoryStack>

              {/* Second stacked bar showing comparison data */}
              <VictoryStack>
              {availableLocations.map((location, index) => (
                  <VictoryBar
                    alignment='start'
                    // barWidth={10}
                    key={location}
                    data={chartData}
                    
                    y={(datum) => {
                      const value = Number(datum[location]);
                      return isNaN(value) ? 0 : value;
                    }}
                    style={{ data: { fill: colors[index % colors.length] }}}
                    barRatio={0.8}
                    animate={{
                      duration: 500,
                      onLoad: { duration: 300 }
                    }}
                    events={[{
                      target: "data",
                      eventHandlers: {
                        onClick: handleBarClick
                      }
                    }]}
                  />
                ))}
              </VictoryStack>
            </VictoryGroup>
          </VictoryChart>
        </div>

      {selectedCategory && (
        <div key={`detail-chart-${selectedCategory}`} >
          <div className="h-48 w-full object-cover md:h-full md:w-1/2">
          <h3 className="text-xl font-semibold mb-2">{selectedCategory} Breakdown</h3>
            <VictoryChart
              theme={VictoryTheme.material}
              domainPadding={{ x: [50, 0] }}
              height={250}
              width={500}
              standalone={true}
              containerComponent={
                <VictoryContainer
                  responsive={true}
                  height={300}
                  width={500}
                />
              }
            >
              <VictoryAxis 
                style={{
                  tickLabels: { 
                    angle: -45,
                    textAnchor: 'end',
                    fontSize: 6,
                    padding: 4
                  }
                }}
              />
              <VictoryAxis dependentAxis />
              <VictoryBar
                data={getCategoryDetails(selectedCategory)}
                labels={({ datum }) => datum.y}
                labelComponent={
                  <VictoryLabel dy={-10} style={{ fontSize: 10, fill: "#000000" }}/>
                }
                style={{ data: { fill: "#6366f1", width: 8 }}}
                barRatio={0.8}
                animate={{
                  duration: 500,
                  onLoad: { duration: 300 }
                }}
              />
            </VictoryChart>
          </div>
        </div>
      )}
    
    </div>
  );
  
}

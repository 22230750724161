import React, { useContext, useEffect, useState } from 'react';
import { Home, BarChart, Users, Settings, LogOut } from 'lucide-react';
import { signOut } from 'firebase/auth';
import { auth } from '../pages/firebase/config';
import { refreshToken } from '../../components/scripts/RefreshToken';

const iconMap = {
  'Home': Home,
  'BarChart': BarChart,
  'Users': Users,
  'Settings': Settings,
  'LogOut': LogOut
};

export default function Sidebar({ activePage, setActivePage, setIsAuthenticated }) {
  const [navItems, setNavItems] = useState([
    { id: 'home', label: 'Home', icon: Home },
    { id: 'analytics', label: 'Analytics', icon: BarChart },
    { id: 'stockpile', label: 'Stockpiles', icon: Settings },
  ]);

  useEffect(() => {
    const fetchAdminItems = async () => {
      
        const token = localStorage.getItem('token');
        if (!token) return; // Exit early if no token exists

        await fetch('http://198.244.178.3:5000/api/admin/nav-items', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(async response => {
          if (response.ok) {
            const items = await response.json();
            const itemsWithIcons = items.map(item => ({
              ...item,
              icon: iconMap[item.icon]
            }));
            setNavItems(currentItems => {
              const existingIds = new Set(currentItems.map(item => item.id));
              const newItems = itemsWithIcons.filter(item => !existingIds.has(item.id));
              return [...currentItems, ...newItems];
            });
    
          }
        }).catch(() => {});
      };
    

    fetchAdminItems();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('token');
      setIsAuthenticated(false);
    } catch (error) {
      console.log('Signout error:', error);
    }
  };

  return (
    <div className="w-64 bg-white shadow-md">
      <div className="p-4">
        <h1 className="text-2xl font-bold text-gray-800">Dashboard</h1>
      </div>
      <nav className="mt-6">
        {navItems.map(item => (
          <button
            key={item.id}
            className={`flex items-center w-full px-6 py-3 text-left text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              activePage === item.id ? 'bg-gray-100' : ''
            }`}
            onClick={() => setActivePage(item.id)}
          >
            <item.icon className="h-5 w-5 mr-3" />
            {item.label}
          </button>
        ))}
        <button
          className="flex items-center w-full px-6 py-3 text-left text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={handleSignOut}
        >
          <LogOut className="h-5 w-5 mr-3" />
          Sign Out
        </button>
      </nav>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useData } from '../../components/scripts/DataProvider';
import { refreshToken } from '../../components/scripts/RefreshToken';
import { Trash2 } from 'lucide-react';
const masterItemsList = [
  { name: 'Dusk ce.III', category: 'Small Arms' },
  { name: 'Booker Storm Rifle Model 838', category: 'Small Arms' },
  { name: 'Aalto Storm Rifle 24', category: 'Small Arms' },
  { name: '7.92mm', category: 'Small Arms' },
  { name: 'Catara mo. II', category: 'Small Arms' },
  { name: 'KRN886-127 Gast Machine Gun', category: 'Small Arms' },
  { name: 'Malone MK.2', category: 'Small Arms' },
  { name: '12.7mm', category: 'Small Arms' },
  { name: 'PT-815 Smoke Grenade', category: 'Small Arms' },
  { name: 'Ferro 879', category: 'Small Arms' },
  { name: 'Cascadier 873', category: 'Small Arms' },
  { name: '8mm', category: 'Small Arms' },
  { name: 'Cometa T2-9', category: 'Small Arms' },
  { name: 'The Hangman 757', category: 'Small Arms' },
  { name: '0.44', category: 'Small Arms' },
  { name: 'Sampo Auto-Rifle 77', category: 'Small Arms' },
  { name: 'Argentir r.II Rifle', category: 'Small Arms' },
  { name: 'Volta r.I Repeater', category: 'Small Arms' },
  { name: 'Fuscina pi.I', category: 'Small Arms' },
  { name: 'Blakerow 871', category: 'Small Arms' },
  { name: 'KRR2-790 Omen', category: 'Small Arms' },
  { name: 'Clancy Cinder M3', category: 'Small Arms' },
  { name: 'No.2 Loughcaster', category: 'Small Arms' },
  { name: '7.62mm', category: 'Small Arms' },
  { name: 'Brasa Shotgun', category: 'Small Arms' },
  { name: 'Buckshot', category: 'Small Arms' },
  { name: 'The Pitch Gun mc.V', category: 'Small Arms' },
  { name: 'Lionclaw mc.VIII', category: 'Small Arms' },
  { name: 'No.1 "The Liar" Submachinegun', category: 'Small Arms' },
  { name: 'Fiddler Submachine Gun Model 868', category: 'Small Arms' },
  { name: '9mm SMG', category: 'Small Arms' },
  { name: 'KRR3-792 Auger', category: 'Small Arms' },
  { name: 'Clancy-Raca M4', category: 'Small Arms' },
  { name: '8.5mm', category: 'Small Arms' },
  { name: '20 Neville Anti-Tank Rifle', category: 'Heavy Arms' },
  { name: '20mm', category: 'Heavy Arms' },
  { name: 'Venom c.II 35', category: 'Heavy Arms' },
  { name: 'Bane 45', category: 'Heavy Arms' },
  { name: 'A.T.R.P.G. Shell', category: 'Heavy Arms' },
  { name: 'Bonesaw MK.3', category: 'Heavy Arms' },
  { name: 'A.T.R.P.G. Indirect Shell', category: 'Heavy Arms' },
  { name: 'Cremari Mortar', category: 'Heavy Arms' },
  { name: 'Mortar Flare Shell', category: 'Heavy Arms' },
  { name: 'Mortar Shrapnel Shell', category: 'Heavy Arms' },
  { name: 'Mortar Shell', category: 'Heavy Arms' },
  { name: 'BF5 White Ash Flask Grenade', category: 'Heavy Arms' },
  { name: 'Ignifist 30', category: 'Heavy Arms' },
  { name: 'Mounted Bonesaw MK.3', category: 'Heavy Arms' },
  { name: 'Green Ash Grenade', category: 'Small Arms' },
  { name: 'Bombastone Grenade', category: 'Small Arms' },
  { name: 'A3 Harpa Fragmentation Grenade', category: 'Heavy Arms' },
  { name: '150mm', category: 'Heavy Ammunition' },
  { name: 'Mammon 91-b', category: 'Heavy Arms' },
  { name: 'Daucus isg.III', category: 'Heavy Arms' },
  { name: '120mm', category: 'Heavy Ammunition' },
  { name: '300mm', category: 'Heavy Ammunition' },
  { name: '250mm', category: 'Heavy Ammunition' },
  { name: 'Anti-Tank Sticky Bomb', category: 'Heavy Arms' },
  { name: 'Cutler Launcher 4', category: 'Heavy Arms' },
  { name: 'R.P.G. Shell', category: 'Heavy Arms' },
  { name: '68mm AT', category: 'Heavy Ammunition' },
  { name: '75mm Round', category: 'Heavy Ammunition' },
  { name: '40mm Round', category: 'Heavy Ammunition' },
  { name: '30mm', category: 'Heavy Arms' },
  { name: 'Warhead', category: 'Heavy Ammunition' },
  { name: 'Binoculars', category: 'Utility' },
  { name: "Hydra's Whisper", category: 'Utility' },
  { name: 'Listening Kit', category: 'Utility' },
  { name: 'Radio Backpack', category: 'Utility' },
  { name: 'Alligator Charge', category: 'Utility' },
  { name: 'Shovel', category: 'Utility' },
  { name: 'Sledge Hammer', category: 'Utility' },
  { name: 'Abisme AT-99', category: 'Utility' },
  { name: 'Tripod', category: 'Utility' },
  { name: 'Hammer', category: 'Utility' },
  { name: 'Wrench', category: 'Utility' },
  { name: 'Buckhorn CCQ-18', category: 'Utility' },
  { name: 'Gas Mask', category: 'Utility' },
  { name: 'Gas Mask Filter', category: 'Utility' },
  { name: 'Radio', category: 'Utility' },
  { name: 'Rocket Booster', category: 'Utility' },
  { name: 'Bandages', category: 'Medical' },
  { name: 'First Aid Kit', category: 'Medical' },
  { name: 'Trauma Kit', category: 'Medical' },
  { name: 'Blood Plasma', category: 'Medical' },
  { name: 'Soldier Supplies', category: 'Medical' },
  { name: 'Diesel', category: 'Resource' },
  { name: 'Petrol', category: 'Resource' },
  { name: 'Aluminum Alloy', category: 'Resource' },
  { name: 'Bunker Supplies', category: 'Resource' },
  { name: 'Basic Materials', category: 'Resource' },
  { name: 'Explosive Materials', category: 'Resource' },
  { name: 'Maintenance supplies', category: 'Resource' },
  { name: 'Heavy Explosive Materials', category: 'Resource' },
  { name: 'Iron Alloy', category: 'Resource' },
  { name: 'Refined Materials', category: 'Resource' },
  { name: 'Salvage', category: 'Resource' },
  { name: 'Components', category: 'Resource' },
  { name: 'Sulfur', category: 'Resource' },
  { name: 'Aluminum', category: 'Resource' },
  { name: 'Iron', category: 'Resource' },
  { name: 'Wreckage', category: 'Resource' },
  { name: 'Concrete Materials', category: 'Resource' },
  { name: 'Crude Oil', category: 'Resource' },
  { name: "Specialist's Overcoat", category: 'Uniforms' },
  { name: 'Fabri Rucksack', category: 'Uniforms' },
  { name: 'Sapper Gear', category: 'Uniforms' },
  { name: "Grenadier's Baldric", category: 'Uniforms' },
  { name: 'Medic Fatigues', category: 'Uniforms' },
  { name: "Physician's Jacket", category: 'Uniforms' },
  { name: "Legionary's Oilcoat", category: 'Uniforms' },
  { name: 'Recon Camo', category: 'Uniforms' },
  { name: "Outrider's Mantle", category: 'Uniforms' },
  { name: 'Heavy Topcoat', category: 'Uniforms' },
  { name: 'Caoivish Parka', category: 'Uniforms' },
  { name: 'Legionary Fatigues', category: 'Uniforms' },
  { name: 'Infantry Battledress', category: 'Uniforms' },
  { name: "Tankman's Coveralls", category: 'Uniforms' },
  { name: 'Padded Boiler Suit', category: 'Uniforms' },
  { name: 'R-12 - "Salus" Ambulance', category: 'Vehicle' },
  { name: 'Dunne Responder 3e', category: 'Vehicle' },
  { name: 'T3 "Xiphos"', category: 'Vehicle' },
  { name: 'T12 "Actaeon" Tankette', category: 'Vehicle' },
  { name: "O'Brien v.121 Highlander", category: 'Vehicle' },
  { name: 'T5 "Percutio"', category: 'Vehicle' },
  { name: "O'Brien v.101 Freeman", category: 'Vehicle' },
  { name: "O'Brien v.1102", category: 'Vehicle' },
  { name: 'BMS - Aquatipper', category: 'Vehicle' },
  { name: 'Blumfield LK205', category: 'Vehicle' },
  { name: 'R-15 - "Chariot"', category: 'Vehicle' },
  { name: 'Dunne Caravaner 2f', category: 'Vehicle' },
  { name: 'BMS - Universal Assembly Rig', category: 'Vehicle' },
  { name: 'BMS - Class 2 Mobile Auto-Crane', category: 'Vehicle' },
  { name: 'Noble Widow MK. XIV', category: 'Vehicle' },
  { name: 'AA-2 Battering Ram', category: 'Vehicle' },
  { name: '68-45 "Smelter" Heavy Field Gun', category: 'Vehicle' },
  { name: 'Collins Cannon 68mm', category: 'Vehicle' },
  { name: 'Balfour Rampart 40mm', category: 'Vehicle' },
  { name: 'Balfour Wolfhound 40mm', category: 'Vehicle' },
  { name: '120-68 "Koronides" Field Gun', category: 'Vehicle' },
  { name: 'G40 "Sagittarii"', category: 'Vehicle' },
  { name: 'Swallowtail 988/127-2', category: 'Vehicle' },
  { name: 'Balfour Falconer 250mm', category: 'Vehicle' },
  { name: 'BMS - Packmule Flatbed', category: 'Vehicle' },
  { name: 'BMS - Ironship', category: 'Vehicle' },
  { name: 'Type C - "Charon"', category: 'Vehicle' },
  { name: '74c-2 Ronan Meteora Gunship', category: 'Vehicle' },
  { name: '74b-1 Ronan Gunship', category: 'Vehicle' },
  { name: 'HH-d "Peltast"', category: 'Vehicle' },
  { name: 'HH-a "Javelin"', category: 'Vehicle' },
  { name: 'HH-b "Hoplite"', category: 'Vehicle' },
  { name: 'Niska Mk. II Blinder', category: 'Vehicle' },
  { name: 'Niska Mk. I Gun Motor Carriage', category: 'Vehicle' },
  { name: 'BMS - Scrap Hauler', category: 'Vehicle' },
  { name: 'AB-8 "Acheron"', category: 'Vehicle' },
  { name: 'AB-11 "Doru"', category: 'Vehicle' },
  { name: 'Mulloy APC', category: 'Vehicle' },
  { name: 'HC-2 "Scorpion"', category: 'Vehicle' },
  { name: 'Devitt-Caine Mk. IV MMR', category: 'Vehicle' },
  { name: 'H5 "Hatchet"', category: 'Vehicle' },
  { name: 'Devitt Ironhide Mk. IV', category: 'Vehicle' },
  { name: 'H-8 "Kraneska"', category: 'Vehicle' },
  { name: 'H-10 "Pelekys"', category: 'Vehicle' },
  { name: 'Devitt Mk. III', category: 'Vehicle' },
  { name: '86K-a "Bardiche"', category: 'Vehicle' },
  { name: 'Gallagher Outlaw Mk. II', category: 'Vehicle' },
  { name: '85K-b "Falchion"', category: 'Vehicle' },
  { name: '85K-a "Spatha"', category: 'Vehicle' },
  { name: 'Silverhand Chieftain - Mk. VI', category: 'Vehicle' },
  { name: 'Silverhand - Mk. IV', category: 'Vehicle' },
  { name: 'HC-7 "Ballista"', category: 'Vehicle' },
  { name: '03MM "Caster"', category: 'Vehicle' },
  { name: '00MS "Stinger"', category: 'Vehicle' },
  { name: 'Kivela Power Wheel 80-1', category: 'Vehicle' },
  { name: 'RR-3 "Stolon" Tanker.', category: 'Vehicle' },
  { name: 'Dunne Fuelrunner 2d', category: 'Vehicle' },
  { name: 'King Gallant Mk. II', category: 'Vehicle' },
  { name: 'King Spire Mk. I', category: 'Vehicle' },
  { name: 'UV-05a "Argonaut"', category: 'Vehicle' },
  { name: 'UV-24 "Icarus"', category: 'Vehicle' },
  { name: 'Drummond Spitfire 100d', category: 'Vehicle' },
  { name: 'UV-5c "Odyssey"', category: 'Vehicle' },
  { name: 'Drummond Loscann 55c', category: 'Vehicle' },
  { name: 'Drummond 100a', category: 'Vehicle' },
  { name: 'T20 "Ixion" Tankette', category: 'Vehicle' },
  { name: 'BMS - White Whale', category: 'Vehicle' },
  { name: 'R-1 Hauler', category: 'Vehicle' },
  { name: 'Dunne Leatherback 2a', category: 'Vehicle' },
  { name: 'R-5 "Atlas" Hauler', category: 'Vehicle' },
  { name: 'Dunne Loadlugger 3c', category: 'Vehicle' },
  { name: 'R-5b "Sisyphus" Hauler', category: 'Vehicle' },
  { name: 'Dunne Landrunner 12c', category: 'Vehicle' },
  { name: 'R-9 "Speartip" Escort"', category: 'Vehicle' },
  { name: 'Dunne Transport', category: 'Vehicle' },
  { name: 'Concrete Mixer', category: 'Shippables' },
  { name: '68mm Anti-Tank Cannon', category: 'Shippables' },
  { name: '50-500 "Thunderbolt" Cannon', category: 'Shippables' },
  { name: 'Huber Exalt 150mm', category: 'Shippables' },
  { name: 'Huber Lariat 120mm', category: 'Shippables' },
  { name: 'Leary Snare Trap 127', category: 'Shippables' },
  { name: 'Resource Container', category: 'Shippables' },
  { name: 'Shipping Container', category: 'Shippables' },
  { name: 'DAE 1b-2 "Serra"', category: 'Shippables' },
  { name: 'Barbed Wire', category: 'Utility' },
  { name: 'Sandbags', category: 'Utility' },
  { name: 'Metal Beam', category: 'Utility' },
  { name: 'Lamentum mm.IV', category: 'Heavy Arms' },
  { name: '"Typhon" ra.XII', category: 'Heavy Arms' },
  { name: 'Culter Foebreaker', category: 'Heavy Arms' },
  { name: 'Ahti Model 2', category: 'Small Arms' },
  { name: 'Malone Ratcatcher Mk. 1', category: 'Heavy Arms' },
  { name: 'Copper', category: 'Resource' },
  { name: 'Copper Alloy', category: 'Resource' },
  { name: "O'Brien V.113 Gravekeeper", category: 'Vehicle' },
  { name: 'T8 "Gemini"', category: 'Vehicle' },
  { name: 'KLG901-2 Lunaire F', category: 'Heavy Arms' },
  { name: 'The Ospreay', category: 'Utility' },
  { name: 'Tremola Grenade GPb-1', category: 'Heavy Arms' },
  { name: 'Velian Flak Vest', category: 'Uniforms' },
  { name: "Gunner's Breastplate", category: 'Uniforms' },
  { name: "Officialis' Attire", category: 'Uniforms' },
  { name: "Officer's Regalia", category: 'Uniforms' },
  { name: '86K-c "Ranseur"', category: 'Vehicle' },
  { name: 'Gallagher Highwayman Mk. III', category: 'Vehicle' },
  { name: 'Relic Materials', category: 'Resource' },
  { name: 'Oil', category: 'Resource' },
  { name: 'Water', category: 'Resource' },
  { name: 'Coal', category: 'Resource' },
  { name: 'Gravel', category: 'Resource' },
  { name: 'Coke', category: 'Resource' },
  { name: 'Damaged Components', category: 'Resource' },
  { name: 'Heavy Oil', category: 'Resource' },
  { name: 'Enriched Oil', category: 'Resource' },
  { name: 'Construction Materials', category: 'Resource' },
  { name: 'Processed Construction Materials', category: 'Resource' },
  { name: 'Steel Construction Materials', category: 'Resource' },
  { name: 'Assembly Materials I', category: 'Resource' },
  { name: 'Water Bucket', category: 'Utility' },
  { name: "Willow's Bane Model 845", category: 'Heavy Arms' },
  { name: "Willow's Bane Ammo", category: 'Utility' },
  { name: '"Molten Wind" v.II Flame Torch', category: 'Heavy Arms' },
  { name: '"Molten Wind" v.II Ammo', category: 'Utility' },
  { name: '3C-High Explosive Rocket', category: 'Heavy Ammunition' },
  { name: 'R-17 "Retiarius" Skirmisher', category: 'Vehicle' },
  { name: 'DAE 3b-2 "Hades Net"', category: 'Shippables' },
  { name: 'Gallagher Thornfall Mk. VI', category: 'Vehicle' },
  { name: '4C-Fire Rocket', category: 'Heavy Ammunition' },
  { name: 'Flame Ammo', category: 'Heavy Ammunition' },
  { name: '94.5mm', category: 'Heavy Ammunition' },
  { name: 'Assembly Materials II', category: 'Resource' },
  { name: 'Assembly Materials III', category: 'Resource' },
  { name: 'Assembly Materials IV', category: 'Resource' },
  { name: 'Assembly Materials V', category: 'Resource' },
  { name: 'DAE 1o-3 "Polybolos"', category: 'Shippables' },
  { name: 'Huber Starbreaker 94.5mm', category: 'Shippables' },
  { name: 'DAE 2a-1 "Ruptura"', category: 'Shippables' },
  { name: 'Fuel Container', category: 'Shippables' },
  { name: 'Material Pallet', category: 'Shippables' },
  { name: 'Pipe', category: 'Resource' },
  { name: '85V-g "Talos"', category: 'Vehicle' },
  { name: 'T14 "Vesta" Tankette', category: 'Vehicle' },
  { name: "O'Brien V. 130 Wild Jack", category: 'Vehicle' },
  { name: 'Noble Firebrand Mk. XVII', category: 'Vehicle' },
  { name: '945g "Stygian Bolt"', category: 'Vehicle' },
  { name: 'Balfour Stockade 75mm', category: 'Vehicle' },
  { name: 'Rycker 4/3-F Wasp Nest', category: 'Vehicle' },
  { name: 'Niska-Rycker Mk. IX Skycaller', category: 'Vehicle' },
  { name: 'H-19 "Vulcan"', category: 'Vehicle' },
  { name: 'Havoc Charge', category: 'Utility' },
  { name: 'Havoc Charge Detonator', category: 'Utility' },
  { name: 'Mounted Fissura gd.I', category: 'Heavy Arms' },
  { name: 'Silverhand Lordscar - Mk. X', category: 'Vehicle' },
  { name: "O'Brien V.190 Knave", category: 'Vehicle' },
  { name: 'Dunne Dousing Engine 3r', category: 'Vehicle' },
  { name: 'R-12b "Salva"', category: 'Vehicle' },
  { name: 'Catena rt.IV Auto-Rifle', category: 'Small Arms' },
  { name: 'King Jester - Mk.I', category: 'Vehicle' },
  { name: 'T13 "Deioneus" Rocket Battery', category: 'Vehicle' },
  { name: 'No.2B Hawthorne', category: 'Small Arms' },
  { name: 'BMS - Fabricator', category: 'Vehicle' },
  { name: 'E680-S Rudder Lock', category: 'Utility' },
  { name: 'Unstable Substances', category: 'Resource' },
  { name: 'Thermal Shielding', category: 'Resource' },
  { name: 'Rare Alloys', category: 'Resource' },
  { name: 'Armoured Fighting Tractor', category: 'Vehicle' },
  { name: 'Storm Tank', category: 'Vehicle' },
  { name: 'PL-1 Phalanx', category: 'Vehicle' },
  { name: 'Staff Car', category: 'Vehicle' },
  { name: 'Repurposed Truck', category: 'Vehicle' },
  { name: 'Rooster - Junkwagon', category: 'Vehicle' },
  { name: 'Rooster - Lamploader', category: 'Vehicle' },
  { name: 'Rooster - Tumblebox', category: 'Vehicle' },
  { name: 'GA-6 Cestus', category: 'Vehicle' },
  { name: 'Duncans Coin 20mm', category: 'Vehicle' },
  { name: 'Niska Mk. III Scar Twin', category: 'Vehicle' },
  { name: 'AU-A150 Taurine Rigger', category: 'Vehicle' },
  { name: 'Cnute Cliffwrest', category: 'Vehicle' },
  { name: 'AOE-9 Rocket Booster', category: 'Shippables' },
  { name: 'AOE-9 Rocket Body', category: 'Shippables' },
  { name: 'AOE-9 Rocket Warhead', category: 'Shippables' },
  { name: 'Naval Hull Segments', category: 'Shippables' },
  { name: 'Naval Shell Plating', category: 'Shippables' },
  { name: 'Naval Turbine Components', category: 'Shippables' },
  { name: 'Model-7 Evie', category: 'Heavy Ammunition' },
  
];

const EditStockpileOverlay = ({ isOpen, onClose, onSave, allStockpiles }) => {
  

  const [selectedStockpileId, setSelectedStockpileId] = useState('');
  const [expandedCategories, setExpandedCategories] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    items: [],
    timestamp: ''
  });
  const [originalData, setOriginalData] = useState(null);


  if (!isOpen) return null;

  const handleDeleteItem = (itemToDelete) => {
    const newItems = formData.items.filter(item => item !== itemToDelete);
    setFormData({...formData, items: newItems});
  };

  const addNewItem = (category) => {
    // Get all items from master list for this category
    const categoryItems = masterItemsList.filter(item => item.category === category);
    
    // Get current item names in this category
    const existingItemNames = formData.items
      .filter(item => item.category === category)
      .map(item => item.name);
      
    // Filter out items that already exist in the stockpile
    const availableItems = categoryItems.filter(item => 
      !existingItemNames.includes(item.name)
    );
  
    return (
      <div className="mt-2 flex gap-2">
        <select 
          className="flex-grow p-2 border rounded"
          onChange={(e) => {
            const selectedItem = masterItemsList.find(item => item.name === e.target.value);
            const newItems = [...formData.items, { ...selectedItem, quantity: 0 }];
            setFormData({...formData, items: newItems});
          }}
        >
          <option value="">Add new item...</option>
          {availableItems.map(item => (
            <option key={item.name} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const handleStockpileSelect = (e) => {
    const selectedId = e.target.value;
    setSelectedStockpileId(selectedId);
    
    const selectedStockpile = allStockpiles.find(s => s.id === selectedId);
    if (selectedStockpile) {
      const stockpileData = {
        name: selectedStockpile.name,
        location: selectedStockpile.location,
        items: selectedStockpile.items,
        timestamp: selectedStockpile.timestamp
      };
      setFormData(stockpileData);
      setOriginalData(stockpileData); // Store original state
    }
  };

  const handleCancel = () => {
    setFormData(originalData); // Restore original state
    onClose();
  };

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  const renderItemsByCategory = () => {
    const groupedItems = formData.items?.reduce((acc, item) => {
      const category = item.category || 'Uncategorized';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});

    return Object.entries(groupedItems || {}).map(([category, items]) => (
      <div key={category} className="mb-6 border rounded-lg p-3">
        <button 
          onClick={() => toggleCategory(category)}
          className="w-full flex justify-between items-center py-2"
        >
          <h3 className="font-semibold text-lg">{category}</h3>
          <span className="text-xl">
            {expandedCategories[category] ? '▼' : '▶'}
          </span>
        </button>
        
        {expandedCategories[category] && (
          <>
            <div className="space-y-2 mt-2">
            {items.map((item, index) => (
              <div key={index} className="flex gap-2 items-center">
                <input 
                  type="text"
                  value={item.name}
                  readOnly
                  className="flex-grow p-2 border rounded bg-gray-50"
                />
                <input 
                  type="number"
                  value={item.quantity}
                  onChange={(e) => {
                    const newItems = [...formData.items];
                    newItems[formData.items.indexOf(item)].quantity = e.target.value;
                    setFormData({...formData, items: newItems});
                  }}
                  className="w-24 p-2 border rounded"
                />
                <button 
                  onClick={() => handleDeleteItem(item)}
                  className="p-2 text-red-500 hover:text-red-700"
                >
                  <Trash2 size={20} />
                </button>
              </div>
            ))}
            </div>
            {addNewItem(category)}
          </>
        )}
      </div>
    ));
};

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg w-3/4 max-h-[90vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">Edit Stockpile</h2>
        
        {/* Stockpile Selection Dropdown */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Stockpile to Edit:
          </label>
          <select 
            onChange={handleStockpileSelect}
            value={selectedStockpileId}
            className="w-full p-2 border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          >
            <option value="">Select a stockpile...</option>
            {allStockpiles?.map((stockpile) => (
              <option key={stockpile.id} value={stockpile.id}>
                {stockpile.name}
              </option>
            ))}
          </select>
        </div>

        {selectedStockpileId && (
          <div className="space-y-6">
            {/* Basic Info Section - Inline */}
            <div>
              <h3 className="font-semibold text-lg mb-3">Basic Info</h3>
              <div className="flex gap-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <input 
                    type="text"
                    value={formData.name}
                    onChange={(e) => setFormData({...formData, name: e.target.value})}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">Location</label>
                  <input 
                    type="text"
                    value={formData.location}
                    onChange={(e) => setFormData({...formData, location: e.target.value})}
                    className="w-full p-2 border rounded"
                  />
                </div>
              </div>
            </div>

            {/* Inventory Section - Full Width Below */}
            <div>
              <h3 className="font-semibold text-lg mb-3">Inventory</h3>
              {renderItemsByCategory()}
            </div>
          </div>
        )}

        <div className="flex justify-end gap-4 mt-6">
            <button 
              onClick={handleCancel}
              className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
            >
              Cancel
            </button>
            <button 
              onClick={() => onSave(formData)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Save Changes
            </button>
          </div>
      </div>
    </div>
  );
};


const SettingsPage = () => {
  const { handleFileUpload, handleFileDelete, allStockpiles, loading, setData } = useData();
  const [selectedStockpile, setSelectedStockpile] = useState('');
  const [selectedComparison, setSelectedComparison] = useState('');
  const [permissions, setPermissions] = useState({ canDeleteStockpile: false });
  const [isEditOverlayOpen, setIsEditOverlayOpen] = useState(false);
  const [editingStockpile, setEditingStockpile] = useState(null);
  

  

  const handleStockpileChange = (e) => {
    const selectedId = e.target.value;
    setSelectedStockpile(selectedId);
    
    const selectedStockpileData = allStockpiles.find(s => s.id === selectedId);
    if (selectedStockpileData) {
      setData({
        name: selectedStockpileData.name,
        items: selectedStockpileData.items,
        location: selectedStockpileData.location,
        timestamp: selectedStockpileData.timestamp
      });
    }
  };

  const handleComparisonChange = (e) => {
    const selectedCompId = e.target.value;
    setSelectedComparison(selectedCompId);
    
    const selectedComparisonData = allStockpiles.find(s => s.id === selectedCompId);
    if (selectedComparisonData) {
      setData({
        name: selectedComparisonData.name,
        items: selectedComparisonData.items,
        location: selectedComparisonData.location,
        timestamp: selectedComparisonData.timestamp
      });
    }
  };

  const handleFileEdit = (e) => {
    setIsEditOverlayOpen(true);
  };

  const handleSaveEdit = () => {
    // Implement save logic
    setIsEditOverlayOpen(false);
  };

  useEffect(() => {
    const fetchPermissions = async () => {

      const token = await refreshToken();
      if (!token) return;
      
      await fetch('http://198.244.178.3:5000/api/admin/permissions', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(async response => {
        if (response.ok) {
          const perms = await response.json();
          setPermissions(perms);
        }
      }).catch(() => {});
    };
  
    fetchPermissions();
  }, []);

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Stockpiles</h2>
      <div className="flex gap-64">
        <div className="max-w-xl">
          <label 
            htmlFor="stockpile-select" 
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Select Stockpile:
          </label>
            <select 
              id="stockpile-select"
              onChange={handleStockpileChange}
              disabled={loading}
              className="mt-1 block w-full pr-32 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
            <option value="">Select Stockpile...</option>
            {allStockpiles.map((stockpile) => (
              <option key={stockpile.id} value={stockpile.id}>
                {stockpile.name}
              </option>
            ))}
          </select>
        </div>
        <div className="max-w-xl">
          <label 
            htmlFor="comparison-select" 
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Select Comparison Stockpile:
          </label>
            <select 
              id="comparison-select"
              onChange={handleComparisonChange}
              disabled={loading}
              className="mt-1 block w-full pr-32 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
            <option value="">Select Comparison Stockpile...</option>
            {allStockpiles.map((stockpile) => (
              <option key={stockpile.id} value={stockpile.id}>
                {stockpile.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      <div className="flex gap-4 mt-6">
        <button 
          onClick={handleFileUpload}
          className="px-6 py-2 border border-transparent rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700"
          >
          Upload Stockpile
        </button>
        <button 
          onClick={() => handleFileDelete(selectedStockpile)}
          disabled={!permissions.canDeleteStockpile}
          className={`px-6 py-2 border border-transparent rounded-md shadow-sm
            ${
              permissions.canDeleteStockpile 
              ? 'bg-red-500 hover:bg-red-600 text-white' 
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
          Delete Selected Stockpile
        </button>
      </div>

      <div className="flex gap-4 mt-6">
        <button 
          onClick={handleFileEdit}
          className="px-6 py-2 border border-transparent rounded-md shadow-sm bg-blue-500 hover:bg-blue-600 text-white"
        >
          Edit a Stockpile
        </button>
      </div>
        
      <EditStockpileOverlay 
        isOpen={isEditOverlayOpen}
        onClose={() => setIsEditOverlayOpen(false)}
        stockpile={editingStockpile}
        onSave={handleSaveEdit}
        allStockpiles={allStockpiles}
        masterItemsList={masterItemsList}
      />  
    
    <div className="flex gap-4 mt-6">
        <button 
          // onClick={}
          className="px-6 py-2 border border-transparent rounded-md shadow-sm bg-blue-500 hover:bg-blue-600 text-white"
        >
          Crate New Stockpile
        </button>
      </div>
        
     
      
    </div>
  );
};

export default SettingsPage;

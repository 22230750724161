import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithCustomToken, signOut } from 'firebase/auth';
import { auth } from './config';

const db = getFirestore();

export const registerUser = async (username, email, password) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  const user = userCredential.user;
    
  await setDoc(doc(db, 'usernames', username), {
    uid: user.uid,
    email: email
  });
    
  return user;
};

export const loginWithUsername = async (username, password) => {
  const usernameDoc = await getDoc(doc(db, 'usernames', username));
    
  if (!usernameDoc.exists()) {
    throw new Error('Username not found');
  }
    
  const { email } = usernameDoc.data();
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  return userCredential.user;
};

export const signOutUser = async () => {
  await signOut(auth);
  localStorage.removeItem('user');
};

export const signInWithJWT = async (token) => {
  const userCredential = await signInWithCustomToken(auth, token);
  return userCredential.user;
};


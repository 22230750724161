import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC8_3eJMt5pZ_DLqtt2QOCEdL0LSd_wNtU",
  authDomain: "james-devs-signon-app-fh.firebaseapp.com",
  projectId: "james-devs-signon-app-fh",
  storageBucket: "james-devs-signon-app-fh.firebasestorage.app",
  messagingSenderId: "763309657330",
  appId: "1:763309657330:web:004cbafc8a56fb932d946b"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
import React, { useState } from 'react';
import Sidebar from './NavBar';
import Dashboard from './Dashboard';


export default function Layout({ setIsAuthenticated }) {
  const [activePage, setActivePage] = useState('home');
  

  return (
    <div className="flex min-h-screen bg-gray-100">
      <Sidebar activePage={activePage} setActivePage={setActivePage} setIsAuthenticated={setIsAuthenticated} />
      <Dashboard activePage={activePage} />
    </div>
  );
}
import { auth } from '../../components/pages/firebase/config';

export const refreshToken = async () => {
  const user = auth.currentUser;
  if (user) {
    const newToken = await user.getIdToken(true);
    localStorage.setItem('token', newToken);
    return newToken;
  }
};

import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useLoading } from '../../components/handlers/LoadingHandler';
import { Edit, Save } from 'lucide-react';

export default function UsersPage() {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { isLoading, setIsLoading } = useLoading();
  const [editingUser, setEditingUser] = useState(null);
  const [editForm, setEditForm] = useState({
    username: '',
    admin: false,
    regiment: ''
  });

  const handleEdit = async (user) => {
    setEditingUser(user.username);
    setEditForm({
      username: user.username || '',
      admin: user.admin || false,
      regiment: user.regiment || ''
    });

    const response = await fetch('http://198.244.178.3:5000/api/auth/update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        currentUsername: user.username,
        admin: user.admin,
        regiment: user.regiment
      }),
    })
    if (response.ok) {
      // Database update successful
      return true;
    }
    return false;
  };

  const handleSave = async (user) => {
    setEditingUser(null);
    setEditForm({
      username: user.username || '',
      admin: user.admin || false,
      regiment: user.regiment || ''
    });
  };
  
  const db = getFirestore();

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = await getDocs(collection(db, 'usernames'));
      const usersData = usersCollection.docs.map(doc => ({
        username: doc.id,
        ...doc.data()
      }));
      setUsers(usersData);
      setIsLoading(false);
    };
    fetchUsers();
  }, []);

  const filteredUsers = users.filter(user => 
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Users</h2>
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search users..."
          className="w-full p-2 border rounded-md"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredUsers.map((user) => (
          <div key={user.username} className="relative bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow max-w-sm">
            <div className="space-y-2 max-w-lg">
                  {editingUser === user.username ? (
                    <button
                      onClick={() => handleSave(user)}
                      className="absolute -top-3 right-1 px-4 py-2"
                    >
                      <Save className="justify-end mt-4" />
                      </button>
                    ) : (
                      <button
                        onClick={() => handleEdit(user)}
                        className="absolute top-1 -right-1 px-4 py-2"
                      >
                        <Edit className="w-5 h-5 mr-2" />
                      </button>
                    )}
                <div className="flex items-center gap-2">
                  <span className="font-semibold text-gray-600 w-24">Username: </span>
                  <input 
                    type="text" 
                    value={editingUser === user.username ? editForm.username : user.username} 
                    readOnly={editingUser !== user.username}
                    onChange={(e) => setEditForm({...editForm, username: e.target.value})}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 p-2.5 w-48"
                  />
                </div>
                <div className="flex items-center gap-2">
                  <span className="font-semibold text-gray-600 w-24">Admin: </span>
                  <input 
                    type="checkbox" 
                    checked={editingUser === user.username ? editForm.admin : user.admin}
                    disabled={editingUser !== user.username}
                    onChange={(e) => setEditForm({...editForm, admin: e.target.checked})}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 p-2.5"
                  />
                </div>
                <div className="flex items-center gap-2">
                  <span className="font-semibold text-gray-600 w-24">Regiment: </span>
                  <input 
                    type="text" 
                    value={editingUser === user.username ? editForm.regiment : user.regiment || ''}
                    readOnly={editingUser !== user.username}
                    onChange={(e) => setEditForm({...editForm, regiment: e.target.value})}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 p-2.5 w-48"
                  />
                </div>
                
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import { createContext, useState, useContext } from 'react';

export const LoadingContext = createContext(null);

export const useLoading = () => useContext(LoadingContext);

export function LoadingProvider({ children }) {
    const [isLoading, setIsLoading] = useState(false);
    
    return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
        {children}
    </LoadingContext.Provider>
    );
}
import React from 'react';
import { useData } from '../../components/scripts/DataProvider';
import { useLoading } from '../../components/handlers/LoadingHandler';


export default function HomePage() {
  const { data,  error } = useData();
  const { isLoading, setIsLoading } = useLoading();

  if (isLoading) return <div className="text-center text-gray-600">Loading...</div>;
  if (error) return <div className="text-center text-red-600">Error: {error}</div>;
  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Welcome to the Dashboard</h2>
      <p className="text-gray-600">Havent really decided what should go here.
      <br/>
        So for the time being have some links to the wiki and some community sites.
      </p>
      <h2 className="text-xl font-bold mt-6 mb-4 text-gray-800">Useful Links</h2>
      <a 
        href="https://foxhole.wiki.gg/wiki/Foxhole_Wiki" 
        target="_blank"
        className="block px-4 py-2 text-lg text-blue-600 hover:text-blue-800 hover:bg-gray-100 rounded-lg transition-colors duration-200"
      >
        Foxhole Wiki
      </a>

      <a 
        href="https://www.foxholestats.com" 
        target="_blank" 
        className="block px-4 py-2 text-lg text-blue-600 hover:text-blue-800 hover:bg-gray-100 rounded-lg transition-colors duration-200"
      >
        Foxhole Stats
      </a>

      <a 
        href="https://warden.express" 
        target="_blank"
        className="block px-4 py-2 text-lg text-blue-600 hover:text-blue-800 hover:bg-gray-100 rounded-lg transition-colors duration-200"
      >
        Warden Express
      </a>

      <a 
        href="https://foxholelogi.com" 
        target="_blank"
        className="block px-4 py-2 text-lg text-blue-600 hover:text-blue-800 hover:bg-gray-100 rounded-lg transition-colors duration-200"
      >
        Logi Calculator
      </a>

      <a 
        href="https://foxholeplanner.com" 
        target="_blank"
        className="block px-4 py-2 text-lg text-blue-600 hover:text-blue-800 hover:bg-gray-100 rounded-lg transition-colors duration-200"
      >
        Foxhole Planner
      </a>

      <a 
        href="https://www.logiwaze.com/" 
        target="_blank" 
        className="block px-4 py-2 text-lg text-blue-600 hover:text-blue-800 hover:bg-gray-100 rounded-lg transition-colors duration-200"
      >
        Logi Waze
      </a>
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import { signInWithJWT } from './firebase/auth';
import CreateAccountPage from './CreateAccountPage';
import { getFirestore, collection, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import { useLoading } from '../../components/handlers/LoadingHandler';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase/config';


const db = getFirestore();

export default function LoginPage({ setIsAuthenticated }) {
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [showCreateAccount, setShowCreateAccount] = useState(false);
 
  const { setIsLoading } = useLoading();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          // setIsAdmin(idTokenResult.claims.isAdmin);
          setIsAuthenticated(true);
        });
      }
    });

    return () => unsubscribe();
  }, []);

  if (showCreateAccount) {
    return <CreateAccountPage setIsAuthenticated={setIsAuthenticated} setShowCreateAccount={setShowCreateAccount} />;
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!credentials.username?.trim()) {
      setError('Username is required');
      return;
    }
    
    
    try {
      const response = await fetch('http://198.244.178.3:5000/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });
  
      const data = await response.json();
    
      if (!response.ok) {
        switch (data.error) {
          case 'User not found':
            setError('No account found with this username');
            break;
          case 'Invalid password':
            setError('Incorrect password');
            break;
          default:
            setError(data.error || 'Login failed');
        }
        return;
      }

      const user = await signInWithJWT(data.token);
      const idToken = await user.getIdToken();
      localStorage.setItem('token', idToken);

      setIsAuthenticated(true);
      
      setIsLoading(true);
      
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 p-8 bg-white rounded-lg shadow-md">  
        <h2 className="text-2xl font-bold mb-4 text-center">Stockpiler Login</h2>
        {error && <p className="text-red-600 text-center mb-4">{error}</p>}
        <form className="space-y-6">
          <div>
            <input
              name="username"
              type="text"
              required
              className="w-full p-2 border rounded-md bg-white text-black"
              placeholder="Username"
              onChange={(e) => setCredentials({...credentials, username: e.target.value})}
            />
          </div>
          <div>
            <input
              name="password"
              type="password"
              required
              className="w-full p-2 border rounded-md bg-white text-black"
              placeholder="Password"
              onChange={(e) => setCredentials({...credentials, password: e.target.value})}
            />
          </div>
        </form>
        <div className="flex justify-center gap-4 mt-6">
            <button 
              type="button"
              onClick={handleLogin}
              className="px-6 py-2 border border-transparent rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700"
            >
              Sign in
            </button>
            <button
              type="button"
              onClick={() => setShowCreateAccount(true)}
              className="px-6 py-2 border border-transparent rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700"
            >
              Create Account
            </button>
          </div>
        </div>
      </div>
  );
}
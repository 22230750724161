import { React, useContext } from 'react';
import HomePage from '../pages/HomePage';
import AnalyticsPage from '../pages/AnalyticsPage';
import UsersPage from '../pages/UsersPage';
import StockpilesPage from '../pages/StockpilesPage';

// import LiveStatsPage from './pages/LiveStatsPage';


export default function Dashboard({ activePage }) {

  
  const pages = {
    home: HomePage,
    analytics: AnalyticsPage,
    stockpile: StockpilesPage,
    users: UsersPage,
  };

  const ActivePage = pages[activePage];

  return (
    <div className="flex-1 p-10">
      <ActivePage />
    </div>
  );
}
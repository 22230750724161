import React, { createContext, useContext, useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { getFirestore, collection, addDoc, getDocs, serverTimestamp } from 'firebase/firestore';
import { auth } from '../pages/firebase/config';
import { doc, deleteDoc } from 'firebase/firestore'
import { useLoading } from '../handlers/LoadingHandler';

const DataContext = createContext();
const db = getFirestore();

export function useData() {
  return useContext(DataContext);
}

export default function DataProvider({ children }) {
  const [data, setData] = useState({
    name: '',
    items: [],
    location: '',
    timestamp: ''
  });
  const [allStockpiles, setAllStockpiles] = useState([]);
  const { isLoading, setIsLoading } = useLoading();
  const [error, setError] = useState(null);

  const fetchAllStockpiles = async () => {
    setIsLoading(true);
   

    try {
      const stockpilesRef = collection(db, 'stockpiles');
      const snapshot = await getDocs(stockpilesRef);
      const stockpilesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      const idToken = localStorage.getItem('token');
      const regimentResponse = await fetch('http://198.244.178.3:5000/api/auth/checkregi', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      })
      
      if (!regimentResponse.ok) {

      } 
      const  { regiment } = await regimentResponse.json();
      const filteredStockpiles = stockpilesData.filter(stockpile => 
        stockpile?.regiment === regiment
      );
      
      const sortedStockpiles = filteredStockpiles.sort((a, b) => {
        const timeA = a?.timestamp?.toMillis() || 0;
        const timeB = b?.timestamp?.toMillis() || 0;
        return timeB - timeA;
      });
      
      setAllStockpiles(sortedStockpiles || 0);
      
      // Set the newest entry as current data
      if (sortedStockpiles?.[0]) {
        setData(sortedStockpiles[0]);
      }
      
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllStockpiles();
  }, []);

  const saveToFirestore = async (stockpileData) => {
    try {
      const docRef = await addDoc(collection(db, 'stockpiles'), {
        ...stockpileData,
        timestamp: serverTimestamp(),
        uploadedBy: auth.currentUser?.uid || 'anonymous'
      });
      return docRef.id;
    } catch (error) {
      setError(error.message);
      throw error;
    }
  };
  
  const handleFileUpload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xlsx';
    
    input.onchange = async (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      
      setIsLoading(true);
      setError(null);
  
      reader.onload = async (e) => {
        try {
          const workbook = XLSX.read(e.target.result, { type: 'binary' });  
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const parsedData = XLSX.utils.sheet_to_json(worksheet);
      
          const stockpileData = {
            name: file.name.replace('.xlsx', ''),
            items: parsedData.map(row => ({
              name: row.Item || '',
              quantity: row.Quantity || 0,
              category: row.Category || '',
              location: row.Stockpile || '',
              type: row.Type || '',
            }))
          };
          // Save to state
          setData(stockpileData);
          // Save to Firestore
          await saveToFirestore(stockpileData);
          
        } catch (error) {
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      };
      reader.readAsBinaryString(file);
    };
    input.click();
  };

  const handleFileDelete = async (stockpileId) => {
    console.log(`Deleting stockpile with ID: ${stockpileId}`);
    console.log(stockpileId);
    try {
      setIsLoading(true);
      // Delete from Firestore
      await deleteDoc(doc(db, "stockpiles", stockpileId));
      
      // Update local state to remove the deleted stockpile
      setData(prevData => ({
        ...prevData,
        items: prevData.items.filter(item => item.id !== stockpileId)
      }));
      
      setIsLoading(false);
      fetchAllStockpiles();
    } catch (error) {
      setError('Failed to delete stockpile');
      setIsLoading(false);
    }
  };

  return (
    <DataContext.Provider value={{ 
      data, 
      setData,
      error, 
      handleFileUpload,
      handleFileDelete,
      allStockpiles,
      fetchAllStockpiles
    }}>
      {children}
    </DataContext.Provider>
  );
}
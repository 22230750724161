import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './components/pages/firebase/config';
import LoginPage from './components/pages/LoginPage';
import Layout from './components/scripts/Layout';
import { useLoading } from './components/handlers/LoadingHandler';
import './components/css/index.css'
import DataProvider from './components/scripts/DataProvider';
import LoadingOverlay from './components/scripts/LoadingOverlay';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { isLoading, setIsLoading } = useLoading();
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        // setIsAdmin(!!idTokenResult.claims.isAdmin);
        setIsAuthenticated(true);
      }
      setPageLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (pageLoading) {
    return <LoadingOverlay />; // Or a nice loading spinner component
  }

  return (
    <div>
      {!isAuthenticated ? (
        <LoginPage setIsAuthenticated={setIsAuthenticated} />
      ) : (
        <>
          <LoadingOverlay />
            <DataProvider>
              <Layout setIsAuthenticated={setIsAuthenticated} />
            </DataProvider>
        </>
      )}
    </div>
  );
}
export default App;
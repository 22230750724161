import React, { useState } from 'react';
import { useLoading } from '../../components/handlers/LoadingHandler';

import { signInWithJWT } from './firebase/auth';

export default function CreateAccountPage({ setIsAuthenticated, setShowCreateAccount }) {
  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    email: '',
  });
  const [error, setError] = useState('');
  const { setIsLoading } = useLoading();

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    if (!newUser.username || !newUser.password || !newUser.email) {
      setError('Please fill in all fields');
      return;
    }

    try {
      const response = await fetch('http://198.244.178.3:5000/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: newUser.username,
          password: newUser.password,
          email: newUser.email,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const { token } = await response.json();
      const user = await signInWithJWT(token);

      setIsLoading(true);
      setIsAuthenticated(true);

    } catch (error) {
      setError(error.message || 'Account creation failed');
    }
  };



  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-center">Create Account</h2>
        {error && <p className="text-red-600 text-center mb-4">{error}</p>}
        <form className="space-y-6" onSubmit={handleCreateAccount}>
          <div>
            <input
              name="username"
              type="text"
              required
              className="w-full p-2 border rounded-md bg-white text-black"
              placeholder="Username"
              onChange={(e) => setNewUser({...newUser, username: e.target.value})}
            />
          </div>
          <div>
            <input
              name="email"
              type="email"
              required
              className="w-full p-2 border rounded-md bg-white text-black"
              placeholder="Email"
              onChange={(e) => setNewUser({...newUser, email: e.target.value})}
            />
          </div>
          <div>
            <input
              name="password"
              type="password"
              required
              className="w-full p-2 border rounded-md bg-white text-black"
              placeholder="Password"
              onChange={(e) => setNewUser({...newUser, password: e.target.value})}
            />
          </div>
          <div className="flex justify-center gap-4">
            <button
              type="submit"
              className="px-6 py-2 border border-transparent rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={() => setShowCreateAccount(false)}
              className="px-6 py-2 border border-transparent rounded-md shadow-sm text-white bg-red-500 hover:bg-red-100"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
